import React from "react";
import { Link } from "gatsby";

import ContentPage from '../components/ContentPage';
import PageImage from '../components/PageImage';

import image from '../images/undraw_connecting_teams.svg';

const AboutPage = () => {

    return <ContentPage>
        <h1>About Us</h1>
        <p>Our team includes the full range of skills that you need for your project to succeed; here are some of the
            highlights:</p>
        <PageImage image={image} alt='Team' leftAlign={false} />
        <ul>
        <li>More than 30 years of experience in IT development and delivery.</li>
        <li>More than 20 years of developing for the internet.</li>
        <li>Enterprise architecture design for global enterprises.</li>
        <li>Front-line user training and support.</li>
        <li>Developing safety-critical plant control systems and building embedded systems.</li>
        <li>IT management at all levels from daily operations to strategic planning.</li>
        </ul>
        <p>Our team members have created award-winning applications used by more than 10,000 people every day and 
            hundreds of smaller applications that have improved users' productivity and businesses' performance.</p>
        <p>Our passion is always to delight our customers and exceed expectations. We care about the success of your 
            project as much as you do.</p>
        <p>Please <Link to='/contact'>contact us</Link> to discuss your objectives and how we can help you.</p>        
    </ContentPage>
}

export default AboutPage;